<template>
    <div>
        <HeaderSec />

        <div>
            <div class="politica-cont">
      <div class="titulo-pol">AVISO DE PRIVACIDAD</div>
      <p class="texto-pol">LABORATORIO DE ALIMENTOS Y AGUAS DE SONORA S.A. DE C.V., mejor conocido como LABORATORIO DE
ALIMENTOS Y AGUAS DE SONORA, con domicilio en calle GARMENDIA 80 ENTRE VERACRUZ Y NAYARIT,
colonia SAN BENITO, ciudad HERMOSILLO, municipio o delegación HERMOSILLO, c.p. 83190, en la entidad de
SONORA, país MÉXICO, y portal de internet WWW.LAASON.MX, es el responsable del uso y protección de sus datos
personales, y al respecto le informamos lo siguiente:</p>

      <p class="texto-pol"><b>¿Para qué fines utilizaremos sus datos personales?</b></p>

      <p class="texto-pol text4">De manera adicional, utilizaremos su información personal para las siguientes finalidades secundarias que no son
necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor atención:</p>

        <p class="texto-pol lista-pol">● Para gestionar, otorgar y dar seguimiento a los servicios comerciales que ha solicitado.</p>
        <p class="texto-pol lista-pol">● Tramites de facturación.</p>
        <p class="texto-pol lista-pol">● Datos contenidos en los informes de prueba emitidos.</p>
        <p class="texto-pol lista-pol">● Verificar y confirmar su identidad.</p>

        <p class="texto-pol text4">En caso de que no desee que sus datos personales se utilicen para estos fines secundarios, indíquelo a continuación:</p>

        <p class="texto-pol">No consiento que mis datos personales se utilicen para los siguientes fines:</p>


        <p class="texto-pol lista-pol">[ ] Para gestionar, otorgar y dar seguimiento a los servicios comerciales que ha solicitado.</p>
        <p class="texto-pol lista-pol">[ ] Tramites de facturación.</p>
        <p class="texto-pol lista-pol">[ ]  Datos contenidos en los informes de prueba emitidos.</p>
        <p class="texto-pol lista-pol">[ ] Verificar y confirmar su identidad.</p>

        <p class="texto-pol text4">La negativa para el uso de sus datos personales para estas finalidades no podrá ser un motivo para que le neguemos
los servicios y productos que solicita o contrata con nosotros.</p>

        <p class="texto-pol"><b>¿Qué datos personales utilizaremos para estos fines?</b></p>

        <p class="texto-pol text4">Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos
personales:</p>

        <p class="texto-pol lista-pol">● Nombre</p>
        <p class="texto-pol lista-pol">● Registro Federal de Contribuyentes(RFC)</p>
        <p class="texto-pol lista-pol">● Clave única de Registro de Población (CURP)</p>
        <p class="texto-pol lista-pol">● Lugar de nacimiento</p>
        <p class="texto-pol lista-pol">● Fecha de nacimiento</p>
        <p class="texto-pol lista-pol">● Nacionalidad</p>
        <p class="texto-pol lista-pol">● Domicilio</p>
        <p class="texto-pol lista-pol">● Teléfono particular</p>
        <p class="texto-pol lista-pol">● Teléfono celular</p>
        <p class="texto-pol lista-pol">● Correo electrónico</p>
        <p class="texto-pol lista-pol">● Firma autógrafa</p>
        <p class="texto-pol lista-pol">● Firma electrónica</p>
        <p class="texto-pol lista-pol">● Edad</p>
        <p class="texto-pol lista-pol">● Fotografía</p>
        <p class="texto-pol lista-pol">● Correo electrónico institucional</p>
        <p class="texto-pol lista-pol">● Información fiscal</p>

        <p class="texto-pol"><b>¿Con quién compartimos su información personal y para qué fines?</b></p>

        <p class="texto-pol text4">Le informamos que sus datos personales son compartidos dentro y fuera del país con las siguientes personas,
empresas, organizaciones o autoridades distintas a nosotros, para los siguientes fines:</p>

        <table>
            <tr>
                <th>Destinatario de los datos personales</th>
                <th>Finalidad</th>
                <th>Requiere del consentimiento</th>
            </tr>
            <tr>
                <td>SECRETARIA DE SALUD</td>
                <td>SANITARIA</td>
                <td>No</td>
            </tr>
        </table>

        <p class="texto-pol"><b>¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?</b></p>

        <p class="texto-pol text5">Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del
uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de
que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases
de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como oponerse
al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos
ARCO.</p>

        <p class="texto-pol text4">Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva a través del
siguiente medio:</p>

        <p class="texto-pol">correo laasonora@gmail.com</p>

        <p class="texto-pol text4">Para conocer el procedimiento y requisitos para el ejercicio de los derechos ARCO, ponemos a su disposición el
siguiente medio:</p>

        <p class="texto-pol">correo laasonora@gmail.com</p>

        <p class="texto-pol text4">Los datos de contacto de la persona o departamento de datos personales, que está a cargo de dar trámite a las
solicitudes de derechos ARCO, son los siguientes:</p>

        <p class="texto-pol lista2-pol">a) Nombre de la persona o departamento de datos personales: Francisco Alberto Barragan Olivas</p>

        <p class="texto-pol lista2-pol">b) Domicilio: calle GARMENDIA 80 ENTRE VERACRUZ Y NAYARIT, colonia SAN BENITO, ciudad Hermosillo,
municipio o delegación Hermosillo, c.p. 83190, en la entidad de Sonora, país México</p>

        <p class="texto-pol lista2-pol">c) Correo electrónico: laasonora@gmail.com</p>

        <p class="texto-pol"><b>Usted puede revocar su consentimiento para el uso de sus datos personales</b></p>

        <p class="texto-pol text5">Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos
personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su
solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir
tratando sus datos personales. Asimismo, usted deberá considerar que para ciertos fines, la revocación de su
consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su
relación con nosotros.
</p>

        <p class="texto-pol">Para revocar su consentimiento deberá presentar su solicitud a través del siguiente medio:</p>

        <p class="texto-pol">correo electrónico laasonora@gmail.com</p>
        
        <p class="texto-pol text4">Para conocer el procedimiento y requisitos para la revocación del consentimiento, ponemos a su disposición el
siguiente medio:</p>

        <p class="texto-pol">correo electrónico laasonora@gmail.com</p>

        <p class="texto-pol"><b>¿Cómo puede limitar el uso o divulgación de su información personal?</b></p>
        
        <p class="texto-pol text4">Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos los siguientes
medios:</p>

        <p class="texto-pol">correo electrónico laasonora@gmail.com</p>

        <p class="texto-pol"><b>El uso de tecnologías de rastreo en nuestro portal de internet</b></p>
        
        <p class="texto-pol text5">Le informamos que en nuestra página de internet utilizamos cookies, web beacons u otras tecnologías, a través de
las cuales es posible monitorear su comportamiento como usuario de internet, así como brindarle un mejor servicio y
experiencia al navegar en nuestra página. Los datos personales que recabamos a través de estas tecnologías, los
utilizaremos para los siguientes fines:</p>

        <p class="texto-pol lista2-pol">Para confirmar y verificar identidad.</p>
        <p class="texto-pol lista2-pol">Para envío de cotizaciones.</p>

        <p class="texto-pol">Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes:</p>
        
        <p class="texto-pol lista2-pol">Identificadores, nombre de usuario y contraseñas de una sesión</p>
        <p class="texto-pol lista2-pol">Idioma preferido por el usuario</p>
        <p class="texto-pol lista2-pol">Región en la que se encuentra el usuario</p>
        <p class="texto-pol lista2-pol">Tipo de navegador del usuario</p>
        <p class="texto-pol lista2-pol">Tipo de sistema operativo del usuario</p>
        <p class="texto-pol lista2-pol">Fecha y hora del inicio y final de una sesión de un usuario</p>
        <p class="texto-pol lista2-pol">Páginas web visitadas por un usuario</p>
        <p class="texto-pol lista2-pol">Búsquedas realizadas por un usuario</p>
        <p class="texto-pol lista2-pol">Publicidad revisada por un usuario</p>
        <p class="texto-pol lista2-pol">Listas y hábitos de consumo en páginas de compras</p>

        <p class="texto-pol"><b>¿Cómo puede conocer los cambios en este aviso de privacidad?</b></p>
        
        <p class="texto-pol text4">El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos
requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras
prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.</p>

        <p class="texto-pol text4">Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a
través de: correo electrónico laasonora@gmail.com.</p>

        <p class="texto-pol text4">El procedimiento a través del cual se llevarán a cabo las notificaciones sobre cambios o actualizaciones al presente
aviso de privacidad es el siguiente:</p>

        <p class="texto-pol">correo electrónico laasonora@gmail.com</p>

        <p class="texto-pol text6">Última actualización: 21/04/2021</p>

    <modal @click='this.showmodal=true' />

    </div>
        </div>
    <FooterSec :name="this.opc" />
    </div>
</template>

<script>

import HeaderSec from '../HeaderSec.vue';
import FooterSec from '../FooterSec.vue';

export default {
    name: 'PoliticaLaason',
    data(){
        return{
            showmodal: false,
            opc: "laasonService",
            name:"",
            phone:"",
            mail:"",
            message:""

        }
    },
    
    components:{
        HeaderSec,
        FooterSec
    },
}
</script>

<style scoped>

.Bloque1{
    display: block;
    width: 100%;
    height: 175.36231884057972VW;
    background: linear-gradient(243.78deg, #6FBA91 17.49%, #0A4CA1 99.32%);
    text-align: -webkit-center;
    padding-top: 0vw;
}
.B1F1 {
    width: 81.88405797101449VW;
    border-bottom: 1px solid white;
    padding-bottom: 4.830917874396135VW;
    margin-bottom: 6.038647342995169VW;
}
.B1F1C1{
    display: flex;
    align-items: center;
}
.B1F1C1_C1{
    margin-top: 4vw;
}
    .B1F1C1_C1 img{
        width: 13.285024154589372VW;
        height: auto;
    }
.B1F1C1_C2 {
    width: 32vw;
    height: 9vw;
    margin-left: 3.3816425120772946VW;
    padding-top: 5vw;
}
    .B1F1C1_C2 p{
        color: white;
        font-size: 3.140096618357488VW;
        line-height: 4.270531400966184VW;
        font-weight: 700;
        text-align: left;
    }

.B1F1C2{
    display: none;
}
    .B1F2C1 a{
        font-size: 3.140096618357488VW;
        padding: 4.1VW 29.9VW;
        border-radius: 8vw;
        margin-left: 0VW;
        color: white;
        background: linear-gradient(to left, #6FBA91 100%, #76DFA6 100%);
    }

    img.B1F2C2 {
        width: 99.903382VW;
        height: auto;
        margin-top: 13.3vw;
    }

    .B1F2C1_F1 p {
        color: #BBEFE3;
        font-size: 12.077294685990339VW;
        font-weight: 800;
        margin-bottom: 6.038647342995169VW;
        line-height: 7.971014492753622VW;
    }
    .B1F2C1_F1 span {
        font-size: 5.5VW;
    }

    .B1F2C1_F2 p {
        width: 61vw;
        color: white;
        font-size: 3.140096618357488VW;
        font-weight: 400;
        margin-bottom: 7vw;
    }
    .politica-cont {
    padding: 33.625vw 11.56vw 10.625vw;
  }.titulo-pol {
    font-size: 4.831vw;
    line-height: 4.831vw;
    margin-bottom: 12.077vw;
  }p.texto-pol {
    font-size: 2.899vw;
    line-height: 179.5%;
    margin: 4.2552vw 14vw;
}

p.texto-pol.lista2-pol {
    margin: 0 15vw;
    line-height: 3vw;
}
table {
    background-color: transparent;
    margin-left: 6vw;
}
th {
    width: 37vw;
    text-align: left;
    border: 1px solid black;
    font-size: 2.042vw;
    line-height: 179.5%;
    color: black;
}
td {
    text-align: left;
    border: 1px solid black;
    font-size: 2.052vw;
    line-height: 179.5%;
    color: black;
}




.P1 {
    /*text-align: -webkit-center;*/
}
    

@media (min-width: 768px) {

    .Bloque1{
    background: linear-gradient(243.78deg, #6FBA91 17.49%, #0A4CA1 99.32%);
    display: block;
    height: 33.90625VW;
    width: 100%;
    padding-top: 0vw;
}.B1F1 {
    display: flex;
    place-content: center;
    margin: 0vw 9vw;
    padding-bottom: 1vw;
    border-bottom: 1px solid white;
}

.B1F1C1{
    
    margin-right: 33vw;
}
.B1F1C1_C1 {
    margin-top: 0.1VW;
    margin-right: 1.6145833333333335VW;
}
    .B1F1C1_C1 img {
        width: 4.53125VW;
        height: auto;
    }

.B1F1C1_C2 {
    width: 8vw;
    height: 1.5vw;
    padding-top: 0.5vw;
    text-transform: uppercase;
    margin-left: 0vw;
}
.B1F1C1_C2 p {
    font-size: 0.78125VW;
    line-height: 0.9208333333333334VW;
    text-align: left;
}

.B1F1C2{
    display: flex;
    align-items: center;
}

    .B1F1C2_C1 ul{
        display: flex;
        color: white;
        list-style: none;
        font-weight: 500;
        font-size: 0.6770833333333334VW;
        margin-bottom: 0vw;
    }
    .B1F1C2_C1 li{
        margin: 0vw 1.5vw;
        cursor: pointer;
        align-self: center;
    }
    .B1F1C2_C1 a{
        color: white;
    }

.B1F2 {
    display: flex;
}
.B1F2C1 {
    width: 52vw;
    height: 10vw;
    margin-top: 4.739583VW;
}
    .B1F2C1_F1 {
        margin-left: 13vw;
    }
    .B1F2C1_F1 p {
        color: #BBEFE3;
        font-weight: 800;
        font-size: 5.208333333333334VW;
        line-height: 3VW;
        margin-bottom: 2VW;
        text-align: left;
    }
    .B1F2C1_F1 span{
        font-size: 2.083333333333333VW;
    }
.B1F2C1_F2 {
    width: 20vw;
    text-align: left;
    margin-left: -6vw;
}
    .B1F2C1_F2 p {
        width: 20vw;
        color: white;
        font-weight: 400;
        font-size: 0.78125VW;
        line-height: 1.1213541666666667VW;
        margin-bottom: 3vw;
    }
    .B1F2C1 a {
        font-size: 0.6770833333333334VW;
        color: white;
        padding: 1.1VW 3.9VW;
        background: linear-gradient(to left, #6FBA91 100%, #76DFA6 100%);
        border-radius: 8vw;
        margin-left: -16VW;
        cursor: pointer;
    }
    .B1F2C2 {
        width: 35.041667VW !important;
        height: auto !important;
        margin-left: 0vw;
        margin-bottom: 5vw;
        margin-top: -2.78vw !important;
    }

  .P1 h1 {
        font-size: 1.8vw;
        font-weight: 900;
        color: black;
    }
    .P1 p {
        width: 46vw;
        font-size: 1vw;
        color: black;
        font-weight: 600;
    }

    .politica-cont {
    padding: 10.625vw 21.042vw;
}
p.texto-pol {
    width: 47vw;
    font-size: 1.042vw;
    line-height: 179.5%;
    color: black;
    margin: 1vw 6vw;
}

.text3 {
    width: 55vw !important;
}

.text4 {
    width: 51vw !important;
}

.text5 {
    width: 49vw !important;
}

.text6{
    text-align: right;
    margin: 0vw 9vw !important;
}

.titulo-pol {
    text-align: center;
    font-size: 2.344vw;
    line-height: 2.708vw;
    color: black;
    font-family: 'TTSN-Regular';
    margin-bottom: 3vw;
}
p.texto-pol.lista-pol {
    margin: 0 7vw;
}
p.texto-pol.lista2-pol {
    margin: 0 7vw;
    line-height: 1vw;
}
th {
    width: 17vw;
    text-align: left;
    border: 1px solid black;
    font-size: 1.042vw;
    line-height: 179.5%;
    color: black;
}
td {
    text-align: left;
    border: 1px solid black;
    font-size: 1.052vw;
    line-height: 179.5%;
    color: black;
}



}

</style>
